import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import SweetAlert from "react-bootstrap-sweetalert";
import NumberFormat from "react-number-format";
import { Lines } from "react-preloaders";
import { isAuthenticated } from "../auth/isAuth";
import "../styles/orders.css";
import { success, fail } from "../methods/Alert";
import notification from "../audio/notification.mp3";

import { ReactComponent as UpIcon } from "../images/profits.svg";
import { ReactComponent as DownIcon } from "../images/loss.svg";
import { OrderTableHeader } from "./OrderTableHeader";
import OrderInfo from "./OrderInfo";
import { OrderInfoModal } from "./OrderInfoModal";
import moment from "moment";

export default class Orders extends Component {
  state = {
    extId: "",
    listingId: "",
    orders: [],
    isDeliveryOn: false,
    isDeliveryOff: false,
    currency: {},

    // today summery
    dispatched: {},
    dispatchedWeek: [],
    dispatchedPrevWeek: [],
    weekCompleteOrders: [],
    yesterdaySales: {},

    // newOrders alert
    newOrders: 0,

    // show reject modal
    showRejectModal: false,
    orderId: "",
    shipdayApiKey: "",

    // alert
    updatePhoneAlertMsg: false,
    loading: false,
    acceptLoading: false,
    readyLoading: false,

    redirectToLogin: false,

    // pre loading
    preLoading: true,

    info: "",
    order: {},
  };

  componentDidMount = () => {
    // get listing id
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/my`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated().providerToken}`,
        },
      })
      .then((res) => {
        this.setState({
          extId: res.data[0].extId,
          listingId: res.data[0].id,
          isDeliveryOn:
            res.data[0].deliveryDetail.isAvailable === true ? true : false,
          isDeliveryOff:
            res.data[0].deliveryDetail.isAvailable === true ? false : true,
          shipdayApiKey: res.data[0]?.shipdayApiKey,
          currency: res.data[0]?.currency,
        });

        const fromDate = moment()
          .subtract(45, "days")
          .startOf("date")
          .format("yyyy-MM-DD HH:mm:ss");
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v2/user/listings/${res.data[0].id}/delivery_orders`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
              params: {
                from: fromDate
              }
            }
          )
          .then((res) => {
            localStorage.setItem("noOfOrders", res.data.length);

            this.setState({
              orders: res.data,
              weekCompleteOrders: res.data.slice(0, 7),
              preLoading: false,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        this.setState({ redirectToLogin: true });
      });

    setInterval(() => {
      const fromDate = moment()
        .subtract(45, "days")
        .startOf("date")
        .format("yyyy-MM-DD HH:mm:ss");

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_orders`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
            params: {
              from: fromDate
            }
          }
        )
        .then((res) => {
          let oldNoOfOrders = parseInt(localStorage.getItem("noOfOrders"));

          if (oldNoOfOrders !== res.data.length) {
            this.setState({ newOrders: res.data.length - oldNoOfOrders });

            // play notification
            const audioEl = document.getElementById("audio-element");
            audioEl.loop = true;
            audioEl.play();
          }
        })
        .catch((err) => console.log(err));
    }, 30000);
  };

  handleDeliveryOn = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_availability`,
        qs.stringify({ availability: true }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ isDeliveryOn: true, isDeliveryOff: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ updatePhoneAlertMsg: true });
      });
  };

  handleDeliveryOff = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_availability`,
        qs.stringify({ availability: false }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ isDeliveryOff: true, isDeliveryOn: false });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ updatePhoneAlertMsg: true });
      });
  };

  handleStatus = (status, orderId, integrations) => {
    if (status === "rejected") {
      this.setState({ showRejectModal: true, orderId: orderId });
    } else {
      this.setState({ acceptLoading: true, readyLoading: true });
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_orders/${orderId}/status`,
          qs.stringify(
            integrations && integrations.integration === "shipday"
              ? { status: status, triggerShipday: integrations.value }
              : { status: status }
          ),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticated().providerToken}`,
            },
          }
        )
        .then((res) => {
          const fromDate = moment()
            .subtract(45, "days")
            .startOf("date")
            .format("yyyy-MM-DD HH:mm:ss");
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_orders`,
              {
                headers: {
                  Authorization: `Bearer ${isAuthenticated().providerToken}`,
                },
                params: {
                  from: fromDate
                }
              }
            )
            .then((res) => {
              this.setState({
                orders: res.data,
                acceptLoading: false,
                readyLoading: false,
              });

              document.getElementById("order-more-info").click();
              success("Order accept successfully");
            })
            .catch((err) => {
              console.log(err);
              fail();
              this.setState({ acceptLoading: false, readyLoading: false });
            });
        })
        .catch((err) => {
          console.log(err);
          fail();
          this.setState({ acceptLoading: false, readyLoading: false });
        });
    }
  };

  onCancel = () => {
    this.setState({ showRejectModal: false });
  };

  rejectOrder = (orderId) => {
    // this.setState({ });
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_orders/${orderId}/status`,
        qs.stringify({ status: "rejected" }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        const fromDate = moment()
          .subtract(45, "days")
          .startOf("date")
          .format("yyyy-MM-DD HH:mm:ss");
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_orders`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticated().providerToken}`,
              },
              params: {
                from: fromDate
              }
            }
          )
          .then((res) => {
            this.setState({
              orders: res.data,
              showRejectModal: false,
              rejectLoading: false,
            });
            document.getElementById("order-more-info").click();
            success("Order rejected successfully");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  saveCustomInfo = (orderId, listingId, cusData) => {
    this.setState({ loading: true });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${listingId}/delivery_orders/${orderId}/custom_info`,
        { customData: cusData },
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        success();
      })
      .catch((err) => {
        console.log(err);
        fail();
        this.setState({ loading: false });
      });
  };

  newOrders = (e) => {
    e.preventDefault();
    const fromDate = moment()
      .subtract(45, "days")
      .startOf("date")
      .format("yyyy-MM-DD HH:mm:ss");
      
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/user/listings/${this.state.listingId}/delivery_orders`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticated().providerToken}`,
          },
          params: {
            from: fromDate
          }
        }
      )
      .then((res) => {
        localStorage.setItem("noOfOrders", res.data.length);

        this.setState({
          orders: res.data,
          newOrders: 0,
        });

        // stop notification
        const audioEl = document.getElementById("audio-element");
        audioEl.loop = false;
      })
      .catch((err) => console.log(err));
  };

  // close modal
  close = () => {
    this.setState({ updatePhoneAlertMsg: false });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInfo = (order) => {
    if (order.customOrderInfo === undefined) {
      this.setState({ info: "" });
    } else {
      this.setState({ info: order.customOrderInfo.customData });
    }
    this.setState({ order: order });
  };

  // TODO: make utility and move into it
  getCurrencySymbol = (currency) => {
    return currency.symbol || currency.code;
  };

  render() {
    const penddingOrders = this.state.orders.filter(
      (filter) => filter.status === "pending"
    );
    const acceptedOrders = this.state.orders
      .filter((filter) => filter.status === "accepted")
      .sort((a, b) => b.createTime.localeCompare(a.createTime));
    const completeOrders = this.state.orders
      .filter((filter) => filter.status === "dispatched")
      .sort((a, b) => b.createTime.localeCompare(a.createTime));
    const rejectedOrders = this.state.orders
      .filter((filter) => filter.status === "rejected")
      .sort((a, b) => b.createTime.localeCompare(a.createTime));

    const deliveryOrders = this.state.orders
      .filter((filter) => filter.orderType === "delivery")
      .sort((a, b) => b.createTime.localeCompare(a.createTime));
    const pickUpOrders = this.state.orders
      .filter((filter) => filter.orderType === "pickup")
      .sort((a, b) => b.createTime.localeCompare(a.createTime));
    const dineInOrders = this.state.orders
      .filter((filter) => filter.orderType === "dine_in")
      .sort((a, b) => b.createTime.localeCompare(a.createTime));

    const weekTotal = this.state.dispatchedWeek.reduce(
      (currentValue, nextValue) => {
        return currentValue + (nextValue.totalByCard + nextValue.totalByCash);
      },
      0
    );
    const prevWeekTotal = this.state.dispatchedPrevWeek.reduce(
      (currentValue, nextValue) => {
        return currentValue + (nextValue.totalByCard + nextValue.totalByCash);
      },
      0
    );
    const weekCompleteOrders = this.state.weekCompleteOrders.filter(
      (filter) => filter.status === "dispatched"
    );

    const todaySale =
      this.state.dispatched.totalByCard + this.state.dispatched.totalByCash;
    const yesterdaySale =
      this.state.yesterdaySales.totalByCard +
      this.state.yesterdaySales.totalByCash;

    return (
      <React.Fragment>
        {this.state.redirectToLogin ? <Redirect to="/" /> : null}

        {this.state.preLoading ? (
          <Lines
            color="#ff6961"
            background="#F2F2F2"
            animation="slide-down"
            customLoading={this.state.preLoading}
          />
        ) : (
          <React.Fragment>
            {this.state.updatePhoneAlertMsg ? (
              <SweetAlert
                danger
                title="Oops, Something went wrong"
                onConfirm={this.close}
                confirmBtnStyle={{ background: "#f26666", border: 0 }}
              >
                Please add a phone number to enable delivery.
              </SweetAlert>
            ) : null}

            <div className="manage-orders-main-wrapper">
              {this.state.newOrders > 0 ? (
                <button
                  type="button"
                  className="new-orders"
                  onClick={this.newOrders}
                >
                  {this.state.newOrders} New Order
                  {this.state.newOrders > 1 ? "s" : ""}
                </button>
              ) : null}
              <audio className="audio-element" id="audio-element">
                <source src={notification}></source>
              </audio>

              <div className="sales-dispaly-wrapper">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 sales-dispaly-block">
                    <div className="day-stats status-border-green">
                      <span className="day-stats-title">
                        <h3>
                          {`${this.getCurrencySymbol(this.state.currency)} `}
                          <NumberFormat
                            value={(this.state.dispatched
                              ? this.state.dispatched.totalByCard +
                                this.state.dispatched.totalByCash
                              : 0
                            ).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </h3>
                        {todaySale === yesterdaySale ? null : todaySale >
                          yesterdaySale ? (
                          <UpIcon />
                        ) : (
                          <DownIcon />
                        )}
                      </span>
                      <p>Sales Today</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 sales-dispaly-block">
                    <div className="day-stats status-border-orange">
                      <h3>{penddingOrders.length}</h3>
                      <p>Orders Pending</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 sales-dispaly-block sd-padding">
                    <div className="day-stats status-border-blue">
                      <span className="day-stats-title">
                        <h3>
                          {`${this.getCurrencySymbol(this.state.currency)} `}
                          <NumberFormat
                            value={weekTotal.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </h3>
                        {weekTotal >= prevWeekTotal ? <UpIcon /> : <DownIcon />}
                      </span>
                      <p>Sales This Week</p>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 sales-dispaly-block sd-padding">
                    <div className="day-stats status-border-grey">
                      <span className="day-stats-title">
                        <h3>{weekCompleteOrders.length}</h3>
                      </span>
                      <p>Completed Orders This Week</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="select-order-type-main-wrapper">
                <ul class="nav nav-pills" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      id="all-orders-tab"
                      data-toggle="pill"
                      href="#all-orders"
                      role="tab"
                      aria-controls="all-orders"
                      aria-selected="true"
                    >
                      All Orders
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="delivery-tab"
                      data-toggle="pill"
                      href="#delivery-orders"
                      role="tab"
                      aria-controls="delivery-orders"
                      aria-selected="false"
                    >
                      Delivery
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="pick-up-tab"
                      data-toggle="pill"
                      href="#pick-up-orders"
                      role="tab"
                      aria-controls="pick-up-orders"
                      aria-selected="false"
                    >
                      Pick Up
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="dine-in-tab"
                      data-toggle="pill"
                      href="#dine-in-orders"
                      role="tab"
                      aria-controls="dine-in-orders"
                      aria-selected="false"
                    >
                      Dine In
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="completed-tab"
                      data-toggle="pill"
                      href="#completed-orders"
                      role="tab"
                      aria-controls="completed-orders"
                      aria-selected="false"
                    >
                      Completed
                    </a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div
                    class="tab-pane fade show active"
                    id="all-orders"
                    role="tabpanel"
                    aria-labelledby="all-orders-tab"
                  >
                    <OrderTableHeader currency={this.state.currency} />
                    {penddingOrders.length > 0 ? (
                      <React.Fragment>
                        <div className="order-section-main">
                          <p className="order-sec-heading">New Orders</p>
                          <OrderInfo
                            orders={penddingOrders}
                            handleInfo={this.handleInfo}
                            highlightColor="#FFFFFF"
                            fontWeight="500"
                            orderType="pending"
                          />
                        </div>
                      </React.Fragment>
                    ) : null}

                    {acceptedOrders.length > 0 ? (
                      <React.Fragment>
                        <div className="order-section-main">
                          <p className="order-sec-heading">Preparing</p>
                          <OrderInfo
                            orders={acceptedOrders}
                            handleInfo={this.handleInfo}
                          />
                        </div>
                      </React.Fragment>
                    ) : null}

                    {completeOrders.length > 0 ? (
                      <React.Fragment>
                        <div className="order-section-main">
                          <p className="order-sec-heading">Dispatched</p>
                          <OrderInfo
                            orders={completeOrders}
                            handleInfo={this.handleInfo}
                          />
                        </div>
                      </React.Fragment>
                    ) : null}

                    {rejectedOrders.length > 0 ? (
                      <React.Fragment>
                        <div className="order-section-main">
                          <p className="order-sec-heading">Rejected</p>
                          <OrderInfo
                            orders={rejectedOrders}
                            handleInfo={this.handleInfo}
                          />
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="delivery-orders"
                    role="tabpanel"
                    aria-labelledby="delivery-tab"
                  >
                    <OrderTableHeader />
                    {deliveryOrders.length > 0 ? (
                      <React.Fragment>
                        <div className="order-section-main">
                          <p className="order-sec-heading">Delivery Orders</p>
                          <OrderInfo
                            orders={deliveryOrders}
                            handleInfo={this.handleInfo}
                          />
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pick-up-orders"
                    role="tabpanel"
                    aria-labelledby="pick-up-tab"
                  >
                    <OrderTableHeader />
                    {pickUpOrders.length > 0 ? (
                      <React.Fragment>
                        <div className="order-section-main">
                          <p className="order-sec-heading">Pick Up Orders</p>
                          <OrderInfo
                            orders={pickUpOrders}
                            handleInfo={this.handleInfo}
                          />
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="dine-in-orders"
                    role="tabpanel"
                    aria-labelledby="dine-in-tab"
                  >
                    <OrderTableHeader />
                    {dineInOrders.length > 0 ? (
                      <React.Fragment>
                        <div className="order-section-main">
                          <p className="order-sec-heading">Dine In Orders</p>
                          <OrderInfo
                            orders={dineInOrders}
                            handleInfo={this.handleInfo}
                          />
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="completed-orders"
                    role="tabpanel"
                    aria-labelledby="completed-tab"
                  >
                    <OrderTableHeader />
                    {completeOrders.length > 0 ? (
                      <React.Fragment>
                        <div className="order-section-main">
                          <p className="order-sec-heading">Completed Orders</p>
                          <OrderInfo
                            orders={completeOrders}
                            handleInfo={this.handleInfo}
                          />
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <OrderInfoModal
              order={this.state.order}
              info={this.state.info}
              handleStatus={this.handleStatus}
              saveCustomInfo={this.saveCustomInfo}
              handleChange={this.handleChange}
              loading={this.state.loading}
              acceptLoading={this.state.acceptLoading}
              readyLoading={this.state.readyLoading}
              isShipdayAvailable={this.state.shipdayApiKey}
              currency={this.state.currency}
            />

            {this.state.showRejectModal ? (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, Reject it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => this.rejectOrder(this.state.orderId)}
                onCancel={this.onCancel}
                focusCancelBtn
                btnSize="sm"
                cancelBtnStyle={{ color: "#504f4f", textDecoration: "none" }}
              >
                You will not be able to recover this order!
              </SweetAlert>
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
